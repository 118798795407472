export enum ActionType {
  SetFiltersDrawerOpen = 'SET_FILTERS_DRAWER_OPEN',
}

export type GlobalParamsState = {
  filtersDrawerOpen: boolean
}

export type SetFiltersDrawerOpenAction = {
  type: ActionType.SetFiltersDrawerOpen
  payload: boolean
}

export type GlobalParamsAction = SetFiltersDrawerOpenAction
