import type { IToggle } from '@unleash/nextjs/client'

export function filterUnleashFlags(
  flags: IToggle[],
  keepString: string,
  removeStrings: string[],
) {
  return flags.filter((item) => {
    const name = item.name.toLowerCase()
    const containsKeepString = name.includes(keepString.toLowerCase())
    const containsRemoveString = removeStrings.some((str) => name.includes(str.toLowerCase()))

    const shouldRemove = containsRemoveString && !containsKeepString
    return !shouldRemove
  })
}
