import type { Dispatch } from 'react'
import { createContext, useContext } from 'react'

import type { CartAction, CartState } from './types'

export type CartContextType = {
  state: CartState
  dispatch: Dispatch<CartAction>
}

export const CartContext = createContext<CartContextType>({} as CartContextType)

export const useCartContext = () => useContext(CartContext)
