import env from '@beam-australia/react-env'

export const ASSET_PREFIX = 'dlc-fe'
export const IMAGE_PREFIX = 'images'

export const ALGOLIA_INDEX_PREFIX = env('ALGOLIA_INDEX_PREFIX')

export const DOWNLOAD_CENTER_SERVICE_URL = env('DOWNLOAD_CENTER_SERVICE_URL')

export const ANALYTICS_STRING_SEPARATOR = ';'

export const DOWNLOAD_BUTTON_DATACY = 'download-button'
export const CART_DOWNLOAD_BUTTON_DATACY = 'cart-download-button'
export const SINGLE_DOCUMENT_DOWNLOAD_BUTTON_DATACY =
  'DownloadDocument-TableItem-DownloadButton'

export const STICKY_DOWNLOAD_BUTTON_PAPER_HEIGHT = 73

export const BREADCRUMBS_LINKS = [{ href: '#', label: 'Download Center' }]
