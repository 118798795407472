import { has, omit } from 'lodash'

import type { CartAction, CartState } from './types'
import { ActionType } from './types'

export const reducer = (state: CartState, action: CartAction) => {
  switch (action.type) {
    case ActionType.OpenCart:
      return { ...state, isOpen: true }
    case ActionType.CloseCart:
      return { ...state, isOpen: false }
    case ActionType.ToggleSelectedAsset:
      if (has(state.selectedAssets, action.payload.assetId)) {
        return { ...state, selectedAssets: omit(state.selectedAssets, action.payload.assetId) }
      }
      return {
        ...state,
        selectedAssets: { ...state.selectedAssets, [action.payload.assetId]: action.payload },
      }
    case ActionType.ClearSelectedAsset:
      return { ...state, selectedAssets: omit(state.selectedAssets, action.payload) }
    case ActionType.ClearAllSelectedAssets:
      return { ...state, selectedAssets: {} }
    default:
      return state
  }
}
