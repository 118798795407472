import createCache from '@emotion/cache'
import { prefixer } from 'stylis'
import stylisRTLPlugin from 'stylis-plugin-rtl'

const rtlCache = createCache({
  key: 'rtlcache',
  stylisPlugins: [prefixer, stylisRTLPlugin],
})
const ltrCache = createCache({ key: 'ltrcache' })

export const getEmotionCache = (isRTL: boolean) => (isRTL ? rtlCache : ltrCache)
