import type { SelectedAsset } from '@/types'

export enum ActionType {
  OpenCart = 'OPEN_CART',
  CloseCart = 'CLOSE_CART',
  ToggleSelectedAsset = 'TOGGLE_SELECTED_ASSET',
  ClearSelectedAsset = 'CLEAR_SELECTED_ASSET',
  ClearAllSelectedAssets = 'CLEAR_ALL_SELECTED_ASSETS',
}

export type CartState = {
  isOpen: boolean
  selectedAssets: Record<string, SelectedAsset>
}

export type OpenCartAction = {
  type: ActionType.OpenCart
}

export type CloseCartAction = {
  type: ActionType.CloseCart
}

export type ToggleSelectedAssetAction = {
  type: ActionType.ToggleSelectedAsset
  payload: SelectedAsset
}

export type ClearSelectedAssetAction = {
  type: ActionType.ClearSelectedAsset
  payload: string
}

export type ClearAllSelectedAssetsAction = {
  type: ActionType.ClearAllSelectedAssets
}

export type CartAction =
  | OpenCartAction
  | CloseCartAction
  | ToggleSelectedAssetAction
  | ClearSelectedAssetAction
  | ClearAllSelectedAssetsAction
