import type React from 'react'
import type { PropsWithChildren } from 'react'
import { useReducer } from 'react'

import { useDeepCompareMemo } from 'use-deep-compare'

import { CartContext } from './CartContext'
import { reducer } from './reducer'
import type { CartState } from './types'

export const CartProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const initialState: CartState = {
    isOpen: false,
    selectedAssets: {},
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <CartContext.Provider
      value={useDeepCompareMemo(() => ({ state, dispatch }), [state, dispatch])}
    >
      {children}
    </CartContext.Provider>
  )
}
