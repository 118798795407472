import type { Dispatch } from 'react'
import { createContext, useContext } from 'react'

import type { GlobalParamsAction, GlobalParamsState } from './types'

export type GlobalParamsContextType = {
  state: GlobalParamsState
  dispatch: Dispatch<GlobalParamsAction>
}

export const GlobalParamsContext = createContext<GlobalParamsContextType>(
  {} as GlobalParamsContextType,
)

export const useGlobalParamsContext = () => useContext(GlobalParamsContext)
