import type { GlobalParamsAction, GlobalParamsState } from './types'
import { ActionType } from './types'

export const reducer = (state: GlobalParamsState, action: GlobalParamsAction) => {
  switch (action.type) {
    case ActionType.SetFiltersDrawerOpen:
      return { ...state, filtersDrawerOpen: action.payload }
    default:
      return state
  }
}
