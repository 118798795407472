import logger from '@knauf-group/ct-shared-nextjs/logger'

import { InMemoryCache } from '@/services/InMemoryCache'

type Config<T> = {
  key: string
  defaultValue: T
  expirationInSec: number
  fetchIfMissingFn: () => Promise<T>
  useStaleDataWhileInvalidate?: boolean
}

export const withCache = <T>(config: Config<T>): T | Promise<T> => {
  const {
    key,
    defaultValue,
    expirationInSec,
    fetchIfMissingFn,
    useStaleDataWhileInvalidate = true,
  } = config

  const keyExistsInCache = InMemoryCache.has(key)
  const valueInCache = InMemoryCache.get(key, useStaleDataWhileInvalidate)

  if (keyExistsInCache) {
    // key is not expired
    return valueInCache
  }

  if (valueInCache) {
    // key is expired but we want to use expired value as stale data until we revalidate it
    fetchIfMissingFn()
      .then((value) => {
        InMemoryCache.set(key, value, expirationInSec)
      })
      .catch(() => {
        logger.error(`Error while fetching ${key}`)
        // on error, we want to use the old value again
        InMemoryCache.set(key, valueInCache, expirationInSec)
      })

    return valueInCache
  }

  // key was never set in cache

  return fetchIfMissingFn()
    .then((value) => {
      InMemoryCache.set(key, value, expirationInSec)

      return value
    })
    .catch(() => {
      logger.error(`Error while fetching ${key}`)
      // couldn't fetch the value, using the provided default
      return defaultValue
    })
}
