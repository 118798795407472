import type { Direction, Theme } from '@mui/material'
import { createTheme } from '@mui/material'
import mediaQuery from 'css-mediaquery'

export const createGlobalThemeBasedOnDevice = (
  deviceType: string,
  theme: Theme,
  direction: Direction,
) => {
  const ssrMatchMedia = (query: string) => ({
    matches: mediaQuery.match(query, {
      // The estimated CSS width of the browser.
      width: deviceType === 'desktop' ? theme.breakpoints.values.lg : '0px',
    }),
  })

  // your MUI theme configuration goes here
  return createTheme(theme, {
    components: {
      MuiUseMediaQuery: {
        defaultProps: {
          ssrMatchMedia,
        },
      },
    },
    direction,
  })
}
