import type React from 'react'
import type { PropsWithChildren } from 'react'
import { useReducer } from 'react'

import { useDeepCompareMemo } from 'use-deep-compare'

import { GlobalParamsContext } from './GlobalParamsContext'
import { reducer } from './reducer'
import type { GlobalParamsState } from './types'

export const GlobalParamsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const initialState: GlobalParamsState = {
    filtersDrawerOpen: false,
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GlobalParamsContext.Provider
      value={useDeepCompareMemo(() => ({ state, dispatch }), [state, dispatch])}
    >
      {children}
    </GlobalParamsContext.Provider>
  )
}
