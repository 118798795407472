import type { PropsWithChildren } from 'react'

import { CartProvider } from './CartContext'
import { GlobalParamsProvider } from './GlobalParamsContext'

export const StoreProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <GlobalParamsProvider>
      <CartProvider>{children}</CartProvider>
    </GlobalParamsProvider>
  )
}
